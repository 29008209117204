// Expose loadFunCaptcha callback function so funcaptcha js can call it
// https://www.funcaptcha.com/advanced-setup/

export {}

declare global {
  interface Window {
    loadFunCaptchaV2: LoadFunCaptchaV2
  }
}
type LoadFunCaptchaV2 = (myEnforcement: MyEnforcement) => void
type MyEnforcement = {
  setConfig: (config: EnforcementConfig) => void
}
// Docs: https://developer.arkoselabs.com/docs/configuration-object
type EnforcementConfig = {
  selector: string
  mode: 'inline'
  onCompleted: (response: {token: string}) => void
  onResize: (response: {height: string; width: string}) => void
  onSuppress: () => void
  data: {
    origin_page: string | null
    blob: string | null
  }
  // `styleTheme` is an optional parameter that allows you to specify the Captcha theme.
  // If not specified, the default theme based on the Captcha key will be used.
  //
  // The following values are supported:
  // - 'default'      : the default theme based on the Captcha key.
  // - 'legacy_light' : the light theme before December 2024.
  // - 'legacy_dark'  : the dark theme before December 2024.
  // - 'light'        : the updated light theme as of December 2024.
  // - 'dark'         : the updated dark theme as of December 2024.
  styleTheme: string | null
}

window.loadFunCaptchaV2 = myEnforcement => {
  const funcaptcha = document.querySelector<HTMLElement>('#funcaptcha')!
  const targetOrigin = funcaptcha.getAttribute('data-target-origin')
  const originPage = funcaptcha.getAttribute('data-origin-page')
  const requireAck = funcaptcha.getAttribute('data-require-ack')
  const captchaVersion = funcaptcha.getAttribute('data-version')
  const dataExchangePayload = funcaptcha.getAttribute('data-data-exchange-payload')
  const styleTheme = funcaptcha.getAttribute('data-style-theme')
  const retrySendTimeout = 5000
  const maxRetryCount = 15
  const V1_BORDER_COLOR = '#BDBDBD'
  const V2_BORDER_COLOR = '#202637'

  myEnforcement.setConfig({
    selector: '#funcaptcha',
    mode: 'inline',
    onCompleted: (response: {token: string}) => {
      const sessionToken = response.token
      parent.postMessage({event: 'captcha-complete', sessionToken}, targetOrigin || '')
    },
    onResize: response => {
      const width = parseInt(response.width, 10)
      const height = parseInt(response.height, 10)

      const styles: Record<string, string> = {
        'border-radius': '6px',
        'box-sizing': 'border-box',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
      }

      if (styleTheme !== 'light') {
        styles.border = `1px solid ${captchaVersion === '1' ? V1_BORDER_COLOR : V2_BORDER_COLOR}`
      }

      for (const prop in styles) {
        funcaptcha.style.setProperty(prop, styles[prop]!)
      }

      let acked = false
      window.addEventListener('message', e => (acked = e?.data?.event === 'captcha-loaded-ack'))
      const sendCaptchaLoaded = (retryCount: number) => {
        if (!acked && retryCount < maxRetryCount) {
          // we need to send the height and width to the parent to properly show all the contents of the captcha
          parent.postMessage({event: 'captcha-loaded', height, width}, targetOrigin || '')
          setTimeout(() => sendCaptchaLoaded(retryCount + 1), retrySendTimeout)
        }
      }
      if (requireAck === 'true') {
        setTimeout(() => sendCaptchaLoaded(0), retrySendTimeout)
      }

      parent.postMessage({event: 'captcha-loaded', height, width}, targetOrigin || '')
    },
    onSuppress: () => {
      parent.postMessage({event: 'captcha-suppressed'}, targetOrigin || '')
    },
    data: {
      origin_page: originPage,
      blob: dataExchangePayload,
    },
    styleTheme,
  })
}
